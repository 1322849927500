import React from 'react'

import classes from './Press.module.css'

import FMH from '../../images/press-fmh.png'
import SNM from '../../images/press-snm.png'
import WPA from '../../images/press-wpa.png'

import LeMonde from '../../images/press-le-monde.png'
import ArcInfo from '../../images/press-arc-info.png'
import CanalAlpha from '../../images/press-canal-alpha.png'
import LeMatin from '../../images/press-le-matin.png'

export default ()=> (
    <div className={classes.Wrapper}>
        <div style={{maxWidth: 1170, padding: '40px 15px', margin: 'auto'}} >
            <h2>Standard de Qualité</h2>
            <p style={{maxWidth: 800, margin: 'auto', marginBottom: 24}} className={classes.subPressContent}>
                Nos thérapeutes ont été minutieusement sélectionnés. Ils ont des années d’expérience dans leur domaine et sont enregistrés avec une ou plusieurs des institutions suivantes:
            </p>
            {/* <div className={classes.Logos}>
                <img className={classes.Logo} src={ANPP} alt="Forbes Logo"/>
                <img className={classes.Logo} src={AVP} alt="Fiancial Times Log"/>
                <img className={classes.Logo} src={FMH} alt="BBC Logo"/>
                <img className={classes.Logo} src={SNM} alt="Channel 5 Logo"/>
                <img className={classes.Logo} src={WPA} alt="Telegraph Logo"/>
            </div> */}

        </div>
    </div>

)

export const Journals =() => (
    <div className={classes.Wrapper} style={{backgroundColor:'rgb(234, 234, 234)'}}>
        <div style={{maxWidth: 1170, padding: '20px 15px', margin: 'auto'}} >
            <div style={{fontWeight: 400, marginBottom: 14, color: '#4D4D4D', fontSize: '0.8rem'}}>PARUS DANS</div>
            <div className={classes.Logos} style={{maxWidth: 500}}>
                <img className={classes.LogoJournal} src={LeMonde} alt="Parus dans LeMonde"/>
                <img className={classes.LogoJournal} src={CanalAlpha} alt="Parus dans Canal Alpha"/>
                <img className={classes.LogoJournal} src={ArcInfo} alt="Parus dans Arc Info"/>
                <img className={classes.LogoJournal} src={LeMatin} alt="Parus dans Le Matin"/>
            </div>

        </div>
    </div>
)